import axios from 'axios'
import store from '@/store'

//1创建实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 10000
})

// 2.axios拦截器
//axios.interceptors这样是全局
//2.1请求拦截
service.interceptors.request.use(config => {
	// console.log(config);

	//拦截什么，做什么
	//1.比如config中的一些信息不符合服务器的要求

	//2.比如每次发送网络请求时，都希望在界面中显示一个请求的图标

	//3.某些网络请求（比如登录（token）），必须携带一些特殊信息
	var token=store.state.globalData.token;
	if(token){
		config.headers['Authorization'] = token;
	}
	return config;
}, error => {
	console.log(error);
})

// 2.2响应拦截
service.interceptors.response.use(response => {
	// console.log(res);
	return response.data;
}, error => {
	console.log(error);

})



//3.发送真正的网络请求
//instance实例 本身返回一个promise ---看源码
export default service
