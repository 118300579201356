import request from '@/utils/request'

export function loginByOpenid(params) {
  return request({
    url: '/openApi/user/loginByOpenid',
    method: 'get',
    params: params
  })
}

export function createToken(params) {
  return request({
    url: '/openApi/user/createToken',
    method: 'get',
    params: params
  })
}

export function getWxJsConfig(params) {
  return request({
    url: '/userApi/user/getWxJsConfig',
    method: 'get',
    params: params
  })
}

export function getAddress(params) {
  return request({
    url: '/userApi/user/getAddress',
    method: 'get',
    params: params
  })
}


